import React from "react";
import Header from "src/components/Header";
import Footer from "src/components/Footer";

class Page extends React.Component {
  render() {
    return (
      <div>
        <Header></Header>
        <div className="main row">
          <div className="sidebar three columns">
            <ul className="side-nav">
              <li className="first overview">
                <a href="/imodium-behandelen-diarree/">
                  {" "}
                  IMODIUM® Geneesmiddelen{" "}
                </a>
              </li>
              <li>
                <a href="/imodium-behandelen-diarree/imodium-instant/">
                  IMODIUM® Instant Smelttablet
                </a>
              </li>
              <li className="active last">
                <a href="/imodium-behandelen-diarree/imodiumreg-capsules/">
                  IMODIUM® Capsules
                </a>
              </li>
            </ul>
            <div className="widget">
              <div className="inner">
                <h4>Hoe werkt IMODIUM®?</h4>
                <div className="img">
                  <img
                    src="/assets/files/widgets/images/widget.jpeg"
                    width="170"
                    height="102"
                    alt="Hoe werkt IMODIUM®?"
                  />
                </div>
                <p>
                  Volgend filmpje toont je de werking van{" "}
                  <strong>
                    IMODIUM<sup>®</sup>
                  </strong>
                  : hoe <strong>diarree</strong> belangrijke vloeistoffen en
                  mineralen aan je lichaam onttrekt en hoe{" "}
                  <strong>
                    IMODIUM<sup>®</sup>{" "}
                  </strong>
                  het natuurlijke evenwicht in je darm herstelt.
                </p>
                <a href="/hoe-werkt-imodium-instant/">Meer informatie</a>
              </div>
            </div>
          </div>
          <div className="page nine columns page-44">
            <h1>IMODIUM® Capsules</h1>
            <div className="row">
              <div className="six columns">
                <img
                  alt
                  src="/assets/files/products/images/imodium-harde-capsules2.png"
                />
              </div>
              <div className="six columns">
                <h3>
                  IMODIUM® Capsules: bij symptomatische behandeling van diarree
                </h3>
                <ul>
                  <li>Makkelijk te slikken capsules</li>
                  <li>Sinds 30 jaar werkzaam tegen diarree</li>
                  <li>Snel en doeltreffend</li>
                </ul>
              </div>
            </div>
            <p>
              <a href="http://bit.ly/2j7gXeh" target="_blank">
                <img
                  alt
                  src="/assets/files/pages/bijlsuiter.jpeg"
                  style={{
                    width: "48px",
                    height: "47px"
                  }}
                />
                Bijsluiter downloaden IMODIUM® Capsules
              </a>
            </p>
            <h4>Gebruik van IMODIUM® Capsules bij diarree</h4>
            <p>
              IMODIUM<sup>®</sup> capsules bevat 2 mg{" "}
              <a href="/hoe-werkt-imodium-instant/werkzame-stof-loperamide/">
                loperamide
              </a>
              . Het wordt gebruikt bij de symptomatische behandeling van{" "}
              <strong>acute diarree</strong> (plotselinge, kortstondige) en{" "}
              <strong>chronische diarree</strong> van diverse oorsprong.
            </p>
            <p>
              Het normaliseert de overmatige darmactiviteit, zodat de stoelgang
              weer vaster wordt en de symptomen van diarree verdwijnen. Zo wordt
              uitdroging door verlies van vocht en mineralen voorkomen. IMODIUM
              <sup>®</sup> capsules kunnen ook aanbevolen worden bij
              reizigersdiarree. Bij <strong>reizigersdiarree</strong> moet een
              behandeling van 2 dagen volstaan.
            </p>
            <p>
              IMODIUM<sup>®</sup> Capsules voor de symptomatische behandeling
              van diarree bestaat als verpakking met 6 of 12 capsules. Het
              middel is in de apotheek, drogisterij en supermarkt zonder
              voorschrift te verkrijgen.
            </p>
            <h4>
              IMODIUM<sup>®</sup>&nbsp;Capsules, 2 mg capsules
            </h4>
            <p>
              IMODIUM<sup>®</sup>&nbsp;Capsules is geschikt voor volwassenen en
              kinderen vanaf 8 jaar. Bevat loperamide. Lees altijd de
              bijsluiter.
            </p>
            <h3>
              <img
                alt
                src="/assets/files/pages/werkzame-stof.jpeg"
                style={{
                  width: "26px",
                  height: "26px"
                }}
              />
              &nbsp;Werkzame stof IMODIUM<sup>®</sup> Capsules:
            </h3>
            <p>
              De werkzame stof in IMODIUM<sup>®</sup> Capsules voor
              symptomatische behandeling van diarree en de behandeling van{" "}
              <strong>reizigersdiarree</strong>&nbsp;is{" "}
              <a href="/hoe-werkt-imodium-instant/werkzame-stof-loperamide/">
                loperamide
              </a>{" "}
              (Loperamidehydrochloride).
            </p>
            <p>&nbsp;</p>
            <h3>
              <img
                alt
                src="/assets/files/pages/toepassingsgebied.jpeg"
                style={{
                  width: "26px",
                  height: "26px"
                }}
              />
              &nbsp;Toepassingsgebied IMODIUM<sup>®</sup> Capsules:
            </h3>
            <p>
              Symptomatische behandeling van acute diarree en chronische diarree
              van diverse oorsprong. Kan aanbevolen worden bij reizigersdiarree.
              Bij reizigersdiarree moet een behandeling van 2 dagen volstaan.
            </p>
            <p>&nbsp;</p>
            <h4>
              Dosering IMODIUM<sup>®</sup> Capsules:
            </h4>
            <ul>
              <li>
                <strong>Volwassenen</strong>
                <ul>
                  <li>
                    Bij acute (plotselinge) diarree: Begin met 2 capsules (4
                    mg). Neem daarna na elke daaropvolgende losse ontlasting 1
                    capsule (2 mg), zolang de diarree duurt. Neem nooit meer dan
                    8 capsules (16 mg) op één dag.
                  </li>
                  <li>
                    Bij chronische (langdurige) diarree: Begin met 2 capsules (4
                    mg) per dag. Deze dosis moet worden bijgesteld, totdat u 1
                    tot 2 maal per dag vaste ontlasting heeft. Meestal wordt dit
                    bereikt met 1 tot 6 capsules (2 tot 12 mg) per dag. U mag
                    nooit meer dan 8 capsules (16 mg) op één dag innemen.
                    <br />
                    &nbsp;
                  </li>
                </ul>
              </li>
              <li>
                <strong>Kinderen vanaf 8 jaar:</strong>
                <ul>
                  <li>
                    Bij acute (plotselinge) diarree: :Begin met 1 capsule (2
                    mg). Daarna na elke daaropvolgende losse ontlasting 1
                    capsule (2 mg), zolang de diarree duurt. Het aantal capsules
                    dat een kind op één dag in mag nemen, is afhankelijk van het
                    lichaamsgewicht van het kind. Zie{" "}
                    <a href="https://www.geneesmiddeleninformatiebank.nl/Bijsluiters/h06945.pdf" target="_blank">
                      bijsluiter
                    </a>{" "}
                    voor meer informatie. Bij kinderen dient men er tevens op te
                    letten dat men nooit meer dan 8 capsules per dag toedient.
                  </li>
                  <li>
                    Bij chronische (langdurige) diarree: Begin met 1 capsule (2
                    mg) per dag. Vervolgens deze dosis bijstellen, totdat het
                    kind 1 tot 2 maal per dag vaste ontlasting heeft. Meestal
                    wordt dit bereikt met 1 tot 6 capsules (2 tot 12 mg) per
                    dag. Het aantal capsules dat een kind op één dag in mag
                    nemen, is afhankelijk van het lichaamsgewicht van het kind.
                    Zie{" "}
                    <a href="https://www.geneesmiddeleninformatiebank.nl/Bijsluiters/h06945.pdf" target="_blank">
                      bijsluiter
                    </a>{" "}
                    voor meer informatie. Bij kinderen dient men er tevens op te
                    letten dat men nooit meer dan 8 capsules per dag toedient.
                  </li>
                </ul>
              </li>
            </ul>
          </div>
        </div>
        <Footer></Footer>
        <div id="myModal" className="reveal-modal large">
          <div
            className="flash-wrapper"
            style={{
              "max-width": "100%",
              height: "236px",
              width: "428px",
              margin: "0 auto"
            }}
          >
            <div id="flash">
              <p>
                Your browser must have Adobe Flash installed to see this
                animation.
              </p>
            </div>
          </div>
          <a className="close-reveal-modal">×</a>
        </div>
        <input type="hidden" name="language" defaultValue="nl-BE" id="lg" />
        <script src="//ajax.googleapis.com/ajax/libs/jquery/3.4.1/jquery.min.js" />
         <script src="https://code.jquery.com/jquery-migrate-3.2.0.js"></script>
        <script src="/assets/js/custom.js" />
        <script
          dangerouslySetInnerHTML={{
            __html:
              "window.jQuery || document.write('<script src=\"/assets/js/vendor/jquery-3.4.1.min.js\"><\\/script>')"
          }}
        />
        <script src="/assets/js/vendor/jquery.flexslider-min.js" />
        <script src="/assets/js/vendor/hash.js" />
        <script src="/assets/js/foundation/jquery.foundation.navigation.js" />
        <script src="/assets/js/foundation/jquery.foundation.topbar.js" />
        <script src="/assets/js/foundation/jquery.foundation.forms.js" />
        <script src="/assets/js/foundation/jquery.foundation.reveal.js" />
        <script src="/assets/js/vendor/swfobject.js" />
        <script src="/assets/js/vendor/yepnope.1.5.4-min.js" />
        <script src="/assets/js/app.js" />
        <script
          type="text/javascript"
          dangerouslySetInnerHTML={{
            __html:
              "\nfunction cookiesDirectiveScriptWrapper(){\n// declare teh used cookie-3rd-parties\n}\n"
          }}
        />
         
        <script
          type="text/javascript"
          dangerouslySetInnerHTML={{
            __html:
              "\n// The position of the disclosure ('top' or 'bottom')\n// Number of times to display disclosure. Enter 0 to show it forever!!!!\n// The URI of your privacy policy\ncookiesDirective('bottom',5,'/cookiebeleid');\n"
          }}
        />
      </div>
    );
  }
}

export default Page;
